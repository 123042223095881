import axios from 'axios';

const jwtAxios = axios.create({
  // baseURL: 'http://localhost:8080/api/',
  // baseURL:'http://apidev-env.eba-gfasd2ew.ap-south-1.elasticbeanstalk.com/api/',
  // uat link
  baseURL: 'https://apiuat.gudmed.in/api/',
  //live links
  // baseURL: 'https://api.gudmed.in/api/',
  //test links
  // baseURL: 'https://nerapi.gudmed.in/api/',
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
