/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  PATIENT_COMPLETED_DATA,
  EXCEL_COMPLETE_DATA,
  PATIENT_COMPLETED_PATIENT_NAME,
  PATIENT_COMPLETED_COUNT,
  PATIENT_COMPLETED_OLDCOUNT,
  PATIENT_FILTER_COUNT,
  PATIENTOLD_FILTER_COUNT,
  PATIENTOLDLIST_COMPLETED_DATA,
  FORGOT_PASWD_SUCCESS,
  FORGOT_PASWD_ERROR,
  FETCH_SUCCESS,
  ERX_IMG_OCR_ARR,
  WORD_PREVIEW_CLIENT,
  ERX_IMG_ARR,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
  OTP_TIME,
  PASSWORD_REQUEST_TAG,
  SETPASS_DOCID,
  SETPASS_MOB,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Patient.js';
//Get Patient completed List data
export const onGetPatientCompletedList = ({
  symptoms,
  mobileNbr,
  fromDate,
  toDate,
  mappedDoctor,
  doctorId,
  pName,
  limit,
  offset,
  reportTag,
  disease,
  process,
  prescriptionType = -1,
  careTakerName,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      symptoms,
      mobileNbr,
      fromDate,
      toDate,
      mappedDoctor,
      doctorId,
      pName,
      limit,
      offset,
      reportTag,
      disease,
      process,
      prescriptionType,
      careTakerName,
    };
    try {
      jwtAxios
        .post('v1/docMgmt/patientCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                pName,
                fromDate,
                toDate,
                mappedDoctor,
                doctorId,
                limit,
                offset,
                jwtToken,
                reportTag,
                disease,
                symptoms,
                process,
                prescriptionType,
                careTakerName,
              };
              jwtAxios
                .post('v1/docMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_FILTER_COUNT,
                      payload: data.data.body.filterCount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: getdata,
                    });
                    if (
                      data.data.body.process &&
                      data.data.body.process === 'excel'
                    ) {
                      dispatch({
                        type: EXCEL_COMPLETE_DATA,
                        payload: getdata,
                      });
                    }
                    const getdata1 = data.data.body;
                    if (getdata1) {
                      dispatch({
                        type: PATIENT_COMPLETED_PATIENT_NAME,
                        payload: getdata1.patientNames,
                      });
                      if (getdata1.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata1.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata1.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata1.erxImageOCR[i].erxUrl,
                            erxid: getdata1.erxImageOCR[i].erxId,
                            extension: getdata1.erxImageOCR[i].urlExtension,
                            rownumber: getdata1.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR_ARR,
                          payload: imgocr,
                        });
                      }
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientCompletedList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientCompletedList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientCompletedList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientCompletedList',
          '0',
          err,
        ),
      );
    }
  };
};

//Get Patient completed List data
export const onGetOldPatientCompletedList = ({
  symptoms,
  mobileNbr,
  fromDate,
  toDate,
  mappedDoctor,
  doctorId,
  pName,
  limit,
  offset,
  reportTag,
  disease,
  process,
  prescriptionType = -1,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    const body = {
      symptoms,
      mobileNbr,
      fromDate,
      toDate,
      mappedDoctor,
      doctorId,
      pName,
      limit,
      offset,
      reportTag,
      disease,
      process,
      prescriptionType,
    };
    try {
      console.log('Ererere', body);
      jwtAxios
        .post('v1/docMgmt/patientOldCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                pName,
                fromDate,
                toDate,
                mappedDoctor,
                doctorId,
                limit,
                offset,
                jwtToken,
                reportTag,
                disease,
                symptoms,
                process,
                prescriptionType,
              };
              jwtAxios
                .post('v1/docMgmt/patientOldCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_COMPLETED_OLDCOUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENTOLD_FILTER_COUNT,
                      payload: data.data.body.filterCount,
                    });

                    // dispatch({
                    //   type: PATIENT_COMPLETED_DATA,
                    //   payload: getdata,
                    // });
                    if (
                      data.data.body.process &&
                      data.data.body.process === 'excel'
                    ) {
                      dispatch({
                        type: EXCEL_COMPLETE_DATA,
                        payload: getdata,
                      });
                    } else {
                      dispatch({
                        type: PATIENTOLDLIST_COMPLETED_DATA,
                        payload: getdata,
                      });
                    }
                    const getdata1 = data.data.body;
                    if (getdata1) {
                      dispatch({
                        type: PATIENT_COMPLETED_PATIENT_NAME,
                        payload: getdata1.patientNames,
                      });
                      if (getdata1.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata1.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata1.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata1.erxImageOCR[i].erxUrl,
                            erxid: getdata1.erxImageOCR[i].erxId,
                            extension: getdata1.erxImageOCR[i].urlExtension,
                            rownumber: getdata1.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR_ARR,
                          payload: imgocr,
                        });
                      }
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetOldPatientCompletedList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetOldPatientCompletedList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          console.log('Ererere232', body);

          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOldPatientCompletedList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOldPatientCompletedList',
          '0',
          err,
        ),
      );
    }
  };
};

//Sign up Patient
export const onSignupPatient = ({mobileNbr, pName, limit, offset}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      pName,
      limit,
      offset,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/patientCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                pName,
                limit,
                offset,
                jwtToken,
              };
              jwtAxios
                .post('v1/patMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onSignupPatient',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSignupPatient',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSignupPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSignupPatient', '0', err),
      );
    }
  };
};

//Sign up Patient
export const onForgetPassword = ({
  mobileNbr,
  newpaswd,
  otp,
  isNumber,
  setpassword,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      newpaswd,
      otp,
      isNumber,
      setpassword,
    };
    try {
      jwtAxios
        .post('v1/docMgmt/forgetpaswdjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              if (data.hasOwnProperty.call(data.data, 'body')) {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  mobileNbr,
                  newpaswd,
                  otp,
                  jwtToken,
                  setpassword,
                };
                jwtAxios
                  .post('v1/docMgmt/forgetpaswd', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      // dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: FORGOT_PASWD_SUCCESS,
                        payload: 'Your password has been changed successfully',
                      });
                      dispatch({
                        type: PASSWORD_REQUEST_TAG,
                        payload: '',
                      });

                      dispatch({
                        type: SETPASS_DOCID,
                        payload: '',
                      });
                      sessionStorage.setItem('setpassMobileNo', '');
                      dispatch({
                        type: SETPASS_MOB,
                        payload: '',
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onSignupPatient',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                if (data.data.hasOwnProperty.call(data.data, 'message')) {
                  if (data.data.message.includes('Error')) {
                    if (data.data.message.includes('2')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('2')[1],
                      });
                    } else if (data.data.message.includes('1')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('1')[1],
                      });
                    } else if (data.data.message.includes('3')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('3')[1],
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  }
                }
              }
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSignupPatient',
                  '0',
                  err,
                ),
              );
            }
          } else {
            if (data.hasOwnProperty.call(data, 'message')) {
              if (data.message.includes('Error')) {
                if (data.message.includes('2')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('2')[1],
                  });
                } else if (data.message.includes('1')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('1')[1],
                  });
                } else if (data.data.message.includes('3')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.data.message.split('3')[1],
                  });
                } else {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: 'Invalid OTP',
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSignupPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSignupPatient', '0', err),
      );
    }
  };
};

//Generate Html
export const GenerateERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateHTML', body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
                var clientList = data?.data?.body?.generateWordClient;
                let locArr = [];
                if (clientList?.length > 0) {
                  clientList.map((item) => {
                    if (item?.clientID) {
                      let id = Number(item?.clientID);
                      locArr.push(id);
                    }
                  });
                }
                dispatch({
                  type: WORD_PREVIEW_CLIENT,
                  payload: locArr,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

//Generate Html
export const GenerateOldERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateOldHTML', body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
                var clientList = data?.data?.body?.generateWordClient;
                let locArr = [];
                if (clientList?.length > 0) {
                  clientList.map((item) => {
                    if (item?.clientID) {
                      let id = Number(item?.clientID);
                      locArr.push(id);
                    }
                  });
                }
                dispatch({
                  type: WORD_PREVIEW_CLIENT,
                  payload: locArr,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetTime = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.get('v1/pateRxMgmt/getotpvalidy').then((data) => {
        if (data.status === 200) {
          dispatch({
            type: OTP_TIME,
            payload: data.data.body.description,
          });
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetTime', '0', err),
      );
    }
  };
};
